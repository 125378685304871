









































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { InputBox, TagInputBox, SearchModal, LoadingSpinner } from '@/components';
import { LEARNPACK_CONTENT_TYPE, useDevice } from '@/utils';
import { IBook, ILearnPackContent, ILearnPackExternalLink, ILecture } from '@/models';
import { learnPackSearvice } from '@/services';

import { RountName } from '@/router';
import { LearnPackTable } from '../components';

interface ILearnPackForm {
  title: string;
  tags: string[];
  introduction: string;
  learnPackElements: ILearnPackContent[];
}

export default defineComponent({
  name: 'LearnPackRegister',
  props: {
    learnPackId: {
      type: String,
    },
  },
  components: {
    InputBox,
    TagInputBox,
    LearnPackTable,
    SearchModal,
    LoadingSpinner,
  },
  setup(props, { root }) {
    const { isMobile } = useDevice();

    const isLoading = ref(false);
    const searchType = ref<LEARNPACK_CONTENT_TYPE | null>(null);

    const tagInputValue = ref('');
    const form = reactive<ILearnPackForm>({
      title: '',
      tags: [],
      introduction: '',
      learnPackElements: [],
    });

    const isEditMode = computed(() => !!props.learnPackId);

    function handleSearch(type: LEARNPACK_CONTENT_TYPE) {
      searchType.value = type;
    }

    function handleAddContent(
      type: LEARNPACK_CONTENT_TYPE,
      data: ILecture[] | IBook[] | ILearnPackExternalLink,
      comment: string,
    ) {
      if (type === LEARNPACK_CONTENT_TYPE.Lecture) {
        const existedList = form.learnPackElements
          .filter(existedListItem => existedListItem.elementType === type)
          .map(item => item.contentId);

        (data as ILecture[]).forEach(item => {
          if (existedList.includes(item.lectureId)) return;

          form.learnPackElements.push({
            contentId: item.lectureId,
            elementType: type,
            comment,
            title: item.lectureName,
            thumbnail: item.lectureImg || 'https://learnmoa.com/img/learnmoa-description.png',
          });
        });
      }

      if (type === LEARNPACK_CONTENT_TYPE.Book) {
        const existedList = form.learnPackElements
          .filter(existedListItem => existedListItem.elementType === type)
          .map(item => item.contentId);

        (data as IBook[]).forEach(item => {
          if (existedList.includes(item.bookId)) return;

          form.learnPackElements.push({
            contentId: item.bookId,
            elementType: type,
            comment,
            title: item.bookName,
            thumbnail: item.bookImg || 'https://learnmoa.com/img/learnmoa-description.png',
          });
        });
      }

      if (type === LEARNPACK_CONTENT_TYPE.ExternalLink) {
        const content = data as ILearnPackExternalLink;
        form.learnPackElements.push({
          elementType: type,
          comment,
          thumbnail: content.thumbnail,
          title: content.title,
          link: content.link,
          origin: content.origin,
        });
      }
    }

    async function fetchData() {
      if (!props.learnPackId) return;

      if (isLoading.value) return;

      isLoading.value = true;

      try {
        const data = await learnPackSearvice.getMyDetail(Number(props.learnPackId));
        form.title = data.title;
        form.introduction = data.introduction ?? '';
        form.tags = data.tags;
        form.learnPackElements = data.learnPackElements ?? [];
      } catch {
        root.$router.back();
      } finally {
        isLoading.value = false;
      }
    }

    async function handleClickSubmit() {
      if (isLoading.value) return;

      if (form.title.length < 5 || form.title.length > 50) {
        alert('제목은 최소 5자, 최대 50자입니다.');
        return;
      }

      if (form.learnPackElements.length < 3) {
        alert('콘텐츠를 최소 3개 이상 등록해 주세요.');
        return;
      }

      isLoading.value = true;

      try {
        if (props.learnPackId) {
          await learnPackSearvice.update({
            learnPackId: Number(props.learnPackId),
            title: form.title,
            introduction: form.introduction,
            tags: form.tags,
            learnPackElements: form.learnPackElements,
          });

          alert('수정되었습니다.');

          root.$router.replace({
            name: RountName.LearnPackDetail,
            params: { learnPackId: String(props.learnPackId) },
          });

          return;
        }
        const learnPackId = await learnPackSearvice.create({
          title: form.title,
          introduction: form.introduction,
          tags: form.tags,
          learnPackElements: form.learnPackElements,
        });

        alert('등록되었습니다.');

        root.$router.replace({
          name: RountName.LearnPackDetail,
          params: { learnPackId: String(learnPackId) },
        });
      } catch {
        // pass
      } finally {
        isLoading.value = false;

        if (isEditMode.value) {
          fetchData();
        }
      }
    }

    async function handleClickDelete() {
      if (isLoading.value) return;

      if (!props.learnPackId) return;

      if (!window.confirm('정말 삭제하시겠습니까?')) {
        return;
      }

      isLoading.value = true;

      try {
        await learnPackSearvice.delete(Number(props.learnPackId));

        root.$router.replace('/mypage/?menu=MY_LEARN_PACK');
      } catch {
        //
      } finally {
        isLoading.value = false;
      }
    }

    watch(searchType, () => {
      if (searchType.value) {
        // searchType값이 있으면 모달이 열림 -> 뒤 스크롤 막기
        document.documentElement.style.overflowY = 'hidden';
      } else {
        document.documentElement.style.overflowY = 'auto';
      }
    });

    onMounted(() => {
      if (props.learnPackId) {
        fetchData();
      }
    });

    onBeforeMount(() => {
      if (isMobile.value) {
        alert('현재 PC에서만 가능한 기능입니다.');
        root.$router.back();
      }
    });

    return {
      tagInputValue,
      form,

      isLoading,

      searchType,

      isEditMode,

      handleSearch,
      handleAddContent,
      handleClickSubmit,
      handleClickDelete,
    };
  },
});
